/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Common Styles */
ion-slides,
.slides-ios,
.slides-md {
    --bullet-background: #005DA4;
    --bullet-background-active: #004388;
}
ion-content {
  //--padding-bottom: 85px !important;
}
ion-back-button button span ion-icon {
  color: --ion-color-primary-contrast;
}
ion-back-button {
  --color: #fff;
}
ion-button {
  --background: #E46C0A;
  --background-activated: #Ac5730;
  --background-focused: #Ac5730;
  --background-hover: #Ac5730;
  --border-radius: 12px;
  font-family: Gotham-Medium;
  width: 100%;
  min-height: 50px;
  --box-shadow: none;
  text-transform: lowercase;
  font-size: 11pt;
}
ion-toolbar {
  --background: #005DA4;
  height: 60px;
  display: flex;
}
ion-title {
  //padding-right: 68px;
  text-align: center;
  font-family: Gotham-Medium;
  color: #FFFFFF;
}
ion-title img {
  width: auto !important;
  /*max-width: 23%;*/
  height: 30px;
}
select {
  outline: none;
  min-height: 40px;
  -webkit-appearance: none;
}
ion-input {
  min-height: 40px;
  font-family: Gotham-Medium !important;
  font-size: 11pt !important;
  --color: #6B6B6B !important;
}
ion-footer {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: #005DA4;
}
html, body, p, span {
  font-family: Gotham-Medium;
  font-size: 11pt;
  color: #6B6B6B;
}
h1 {
  font-family: Gotham-Black;
  font-size: 24pt;
}
h2 {
  font-family: Gotham-Black;
  font-size: 22pt;
}
h3 {
  font-family: Gotham-Black;
  font-size: 20pt;
}
h4 {
  font-family: Gotham-Black;
  font-size: 18pt;
}
h5 {
  font-family: Gotham-Black;
  font-size: 14pt;
}
.error {
  display: flex;
  justify-content: flex-end;
  color: red;
  padding: 2px;
  font-family: Gotham-Medium;
  font-size: 9pt;
}

@font-face {
    font-family: Gotham-Black;
    src: url("./assets/fonts/Gotham-Black.ttf");
}
@font-face {
    font-family: Gotham-BlackItalic;
    src: url("./assets/fonts/Gotham-BlackItalic.ttf");
}
@font-face {
    font-family: Gotham-Bold;
    src: url("./assets/fonts/Gotham-Bold.ttf");
}
@font-face {
    font-family: Gotham-BoldItalic;
    src: url("./assets/fonts/Gotham-BoldItalic.ttf");
}
@font-face {
    font-family: Gotham-Book;
    src: url("./assets/fonts/Gotham-Book.ttf");
}
@font-face {
    font-family: Gotham-BookItalic;
    src: url("./assets/fonts/Gotham-BookItalic.ttf");
}
@font-face {
    font-family: Gotham-Light;
    src: url("./assets/fonts/Gotham-Light.ttf");
}
@font-face {
    font-family: Gotham-LightItalic;
    src: url("./assets/fonts/Gotham-LightItalic.ttf");
}
@font-face {
    font-family: Gotham-Medium;
    src: url("./assets/fonts/Gotham-Medium.ttf");
}
@font-face {
    font-family: GGotham-MediumItalic;
    src: url("./assets/fonts/Gotham-MediumItalic.ttf");
}
@font-face {
    font-family: Gotham-Thin;
    src: url("./assets/fonts/Gotham-Thin.ttf");
}
@font-face {
    font-family: Gotham-ThinItalic;
    src: url("./assets/fonts/Gotham-ThinItalic.ttf");
}
@font-face {
    font-family: Gotham-Ultra;
    src: url("./assets/fonts/Gotham-Ultra.ttf");
}
@font-face {
    font-family: Gotham-UltraItalic;
    src: url("./assets/fonts/Gotham-UltraItalic.ttf");
}
@font-face {
    font-family: Gotham-XLight;
    src: url("./assets/fonts/Gotham-XLight.ttf");
}
@font-face {
    font-family: Gotham-XLightItalic;
    src: url("./assets/fonts/Gotham-XLightItalic.ttf");
}

@font-face {
  font-family: 'swis721_blkrnd_btblack';
  src: url('./assets/fonts/swiss_721_black_rounded_bt-webfont.woff');
  src: local('swis721_blkrnd_btblack'), url('./assets/fonts/swiss_721_black_rounded_bt-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MetroGiroIcons';
  src:  url('./assets/fonts/icomoon/MetroGiroIcons.eot?97aglg');
  src:  url('./assets/fonts/icomoon/MetroGiroIcons.eot?97aglg#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon/MetroGiroIcons.ttf?97aglg') format('truetype'),
    url('./assets/fonts/icomoon/MetroGiroIcons.woff?97aglg') format('woff'),
    url('./assets/fonts/icomoon/MetroGiroIcons.svg?97aglg#MetroGiroIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="giro-icon-"], [class*=" giro-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MetroGiroIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.giro-icon-apple:before {
  content: "\e900";
}
.giro-icon-camera:before {
  content: "\e907";
}
.giro-icon-giro:before {
  content: "\e90f";
}
.giro-icon-select-down:before {
  content: "\e90e";
}
.giro-icon-check:before {
  content: "\e908";
}
.giro-icon-anexo:before {
  content: "\e90b";
}
.giro-icon-facebook:before {
  content: "\e90c";
}
.giro-icon-google:before {
  content: "\e90d";
}
.giro-icon-interrogacao:before {
  content: "\e909";
}
.giro-icon-olho:before {
  content: "\e90a";
}
.giro-icon-up:before {
  content: "\e906";
}
.giro-icon-close:before {
  content: "\e904";
}
.giro-icon-extrato:before {
  content: "\e902";
}
.giro-icon-meus-dados:before {
  content: "\e903";
}
.giro-icon-mais:before {
  content: "\e901";
}
.giro-icon-down:before {
  content: "\e905";
}
.giro-icon-filtro:before {
  content: "\e910";
}
.giro-icon-on-boarding:before {
  content: "\e911";
}
.giro-icon-on-boarding-right:before {
  content: "\e912";
}
.giro-icon-on-boarding-left:before {
  content: "\e913";
}
.giro-icon-presente:before {
  content: "\e916";
}
.giro-icon-recarga:before {
  content: "\e917";
}
.giro-icon-vantagens:before {
  content: "\e91b";
}


/** CUSTOM ICON **/
.giro-icon-up::before {
  background: white;
  width: 30px;
  height: 30px;
  display: block;
  top: 1px;
  position: absolute;
  right: 1px;
  z-index: -1;
  border-radius: 100%;
}

.arrowTop {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 120px);
  text-align: right;
  width: 32px;
  padding-right: 10px;
  z-index: 999;
  right: 10px;
}
.arrowTop i {
  color: #005DA4;
  font-size: 32px;
  position: relative;
}
.invisivel {
  display: none;
}
.popover-content {
  width: 100% !important;
  left: 0 !important;
  border-radius: 0 !important;
  background: white !important;
  max-width: 90% !important;
  margin-left: 5%;
}

app-confirm {
  margin: 20px;
}

.popup-banner .modal-wrapper {
  background: rgba(255, 255, 255, 0.50);
  width: 100%;
  height: 100%;
  display: flex;
  .ion-page {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .imagem {
      display: flex;
      justify-content: center;
      background: white;
    }
  }
  .auto-height {
    padding-top: 20px !important;
  }

  .close-modal {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #bababa;
    border: 1px solid #828383;
    color: #828383;
    position: absolute;
    right: -15px;
    z-index: 9999;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 10px;
      line-height: 10px;
    }
  }

}

ion-modal.auto-height {
  &.bottom {
      align-items: flex-end;
  }

  --height: auto;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }
}

ion-textarea {
  border: 1px solid #ccc;
  height: 150px;
  padding: 0 5px;
  textarea {
    height: 150px !important;
  }
}

.textPlain p {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 22px;
}
.textPlain span {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 22px;
}

scroll-content {
  padding-bottom: 0!important;
}

ion-app, ion-nav, ion-tab, ion-tabs, .app-root{
  -webkit-transition: bottom 0.3s;
  transition: bottom 0.3s;
}

ion-modal {
  z-index: 9999999 !important;
}

.declaracao-conteudo {
  p {
    span {
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}
.filtro-modal {
	--width: 90vw;
  &::part(content){
    position: unset;

  }

}
.popup-banner {
	--width: 90vw;
  &::part(content){
    --background: rgba(255, 255, 255, 0.50);
    --width: 100%;

  }
  .ion-page {
    div {
    margin: auto;
   }
  }

}
